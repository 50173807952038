import { createContext, useContext, useReducer } from 'react'
import PropTypes from 'prop-types'

export const GridStateContext = createContext(undefined)
export const GridDispatchContext = createContext(undefined)

const gridReducer = (state, action) => {
  switch (action.type) {
    case 'set': {
      const id = action?.value?.id
      if (id) {
        const newState = {
          ...state,
          [id]: action.value,
        }
        if (typeof window !== 'undefined')
          localStorage.setItem('grid-context', JSON.stringify(newState))
        return newState
      } else {
        localStorage.setItem('grid-context', JSON.stringify(state))
        return state
      }
    }
    case 'clear': {
      localStorage.removeItem('grid-context')
      return {}
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const GridProvider = ({ children }) => {
  let initialData = {}
  if (typeof window !== 'undefined') {
    const json = localStorage.getItem('grid-context')
    initialData = json ? JSON.parse(json) : {}
  }

  const [state, dispatch] = useReducer(gridReducer, initialData)
  return (
    <GridStateContext.Provider value={state}>
      <GridDispatchContext.Provider value={dispatch}>
        {children}
      </GridDispatchContext.Provider>
    </GridStateContext.Provider>
  )
}

GridProvider.propTypes = {
  children: PropTypes.node,
}

const useGridState = (grid) => {
  const context = useContext(GridStateContext)
  if (context === undefined) {
    throw new Error('useGridState must be within a GridProvider')
  }
  return grid ? context[grid] : context
}

const useGridDispatch = () => {
  const context = useContext(GridDispatchContext)
  if (context === undefined) {
    throw new Error('useGridDispatch must be used within a GridProvider')
  }
  return context
}

const useGrid = (grid) => {
  return [useGridState(grid), useGridDispatch()]
}

export { GridProvider, useGrid, useGridState, useGridDispatch }
